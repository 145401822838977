@import "global.scss";
.mainView {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  height: 100%;
  gap: 1rem;

  img {
    width: 24rem;
    height: auto;
  }

  h1 {
    font-weight: 400;
    font-size: 2rem;
    margin: 1rem 0;
    color: $text-primary;
  }

  a {
    color: $primary;
    font-weight: 500;
    text-decoration: underline;
  }

  button {
    margin: 1rem 0;
  }

  p {
    text-align: center;
    color: $text-primary-darker;

    &:last-of-type span {
      font-weight: 600;
    }
  }
}
